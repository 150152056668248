import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/ekeia6Ef1XY"
    bibleGroupSrc="https://pdfhost.io/v/MJqkZIJpo_Microsoft_Word_Bible_Group_Homework_126docx.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Worry and the Christmas Season - Holiday on its Head" />
  </Layout>
)

export default SermonPost
